import type { ConditionalTextBlock as ConditionalTextBlockType } from '@sh24/contentful-service'
import {
  ResultsModule,
  ResultsHistoryModule,
  PrepActionsModule,
  OrdersHistoryModule,
  PrepSummaryModule,
  PrescriptionsModule, PrepCalloutModule,
} from './dynamic-modules'

const DynamicContentModule = ({
  type,
  modules,
  id,
}: {
  type: string,
  modules: ConditionalTextBlockType[],
  id: string,
}) => {
  switch (type) {
    case 'results':
      return <ResultsModule modules={modules} />
    case 'results_history':
      return <ResultsHistoryModule />
    case 'prescriptions':
      return <PrescriptionsModule />
    case 'prep_actions':
      return <PrepActionsModule />
    case 'order_history':
      return <OrdersHistoryModule />
    case 'prep_summary':
      return <PrepSummaryModule />
    case 'prep_callout':
      return <PrepCalloutModule id={id} />
    default:
      return <div>Unknown dynamic content</div>
  }
}

export default DynamicContentModule
