import { ButtonLink, Callout } from '@sh24/ui-components'
import styled from 'styled-components'
import useTranslations from '../../../../utils/use-translations'
import InlineLink from '../../../InlineLink/inline-link'

const CtaButtonOuterWrapper = styled.div`
  div {
      a {
      justify-content: center;
        }
  }
`

const NewToPrepCallout = () => {
  const translations = useTranslations()

  const ctaButton = (
    <CtaButtonOuterWrapper>
      <ButtonLink variation="primary" fullWidth>
        <InlineLink url="/orders/start-prep/prep-intro" text={translations['prepDashboard.prepOrderStatusCard.newToPrep.linkText']} />
      </ButtonLink>
    </CtaButtonOuterWrapper>
  )

  return (
    <Callout
      key="noPrepOrder"
      backgroundColour="/primary300"
      title={translations['prepDashboard.prepOrderStatusCard.newToPrep.title']}
      content={translations['prepDashboard.prepOrderStatusCard.newToPrep.content']}
      ctaButton={ctaButton}
    />
  )
}

export default NewToPrepCallout
