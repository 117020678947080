import { useContext, useEffect } from 'react'
import AccountContext from '../contexts/account-context'

const ensurePrepSubscriptionLoaded = () => {
  const {
    prepSubscription,
    refreshPrepSubscription,
  } = useContext(AccountContext).prepSubscriptionContext

  useEffect(() => {
    const updatePrepSubscription = async () => {
      if (refreshPrepSubscription && !prepSubscription) {
        await refreshPrepSubscription()
      }
    }

    updatePrepSubscription()
  }, [])
}

export default ensurePrepSubscriptionLoaded
