import styled from 'styled-components'
import { BreakpointHelpers } from '@sh24/ui-components'
import useTranslations from '../../../utils/use-translations'
import AvailableServicesButtons from '../OrderAvailability/ReorderButtons/available-services-buttons'

const { mobileOnly, desktopUp, tabletUp } = BreakpointHelpers

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const CenteredP = styled.p`
  text-align: center;
`

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CtaButtonContainer = styled.div`
    ${desktopUp(`
        width: 50%
      `)}

      ${tabletUp(`
        width: 50%
      `)}

    ${mobileOnly(`
      width: 100%;
    `)}
`

const RestartPrepContainer = () => {
  const translations = useTranslations()

  return (
    <Container>
      <div className="mb-lg">
        <CenteredP>{translations['prepDashboard.noActivePrepSubscription']}</CenteredP>
      </div>
      <CtaContainer>
        <h3>{translations['prepDashboard.restartPrep.heading']}</h3>
        <CtaButtonContainer>
          <AvailableServicesButtons
            availableServices={['restartPrep']}
          />
        </CtaButtonContainer>
      </CtaContainer>
    </Container>
  )
}

export default RestartPrepContainer
