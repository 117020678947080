import { format, subDays } from 'date-fns'
import { ButtonLink, Callout } from '@sh24/ui-components'
import styled from 'styled-components'
import { PrepSubscriptionStiTestOrderData } from '@sh24/admin-api-js'
import useTranslations from '../../../../utils/use-translations'
import InlineLink from '../../../InlineLink/inline-link'
import { camelize } from '../../../../utils/helpers'

interface State {
  title: string
  content: string
  button: {
    text: string
    link?: string | null
  } | null
  showStatusUpdate: boolean
}
const CtaButtonOuterWrapper = styled.div`
  div {
    a {
      justify-content: center;
    }
  }
`

const deriveResultsHeldButton = (order: PrepSubscriptionStiTestOrderData, translations: Record<string, string>) => {
  const { consultation } = order

  if (consultation === null) {
    return null
  }

  if (consultation?.state === 'contact_attempt' || consultation?.state === 'scheduled') {
    const stateCamelized = camelize(consultation?.state)
    return {
      text: translations[`prepDashboard.stiTestOrderStatusCard.ctaButtonText.consultation.${stateCamelized}`],
      link: consultation?.schedulingUrl,
    }
  }

  return {
    text: translations['prepDashboard.stiTestOrderStatusCard.ctaButtonText.consultation.other'],
    link: `/account/results/${order.sh24Uid}`,
  }
}

const fetchStatusDetails = (
  order: PrepSubscriptionStiTestOrderData,
  translations: Record<string, string>,
): { [state: string]: State} => (
  {
    created: {
      title: translations['prepDashboard.stiTestOrderStatusCard.title.created'],
      content: translations['prepDashboard.stiTestOrderStatusCard.description.created'],
      button: null,
      showStatusUpdate: true,
    },
    approved: {
      title: translations['prepDashboard.stiTestOrderStatusCard.title.approved'],
      content: translations['prepDashboard.stiTestOrderStatusCard.description.approved'],
      button: null,
      showStatusUpdate: true,
    },
    dispatched: {
      title: translations['prepDashboard.stiTestOrderStatusCard.title.dispatched'],
      content: translations['prepDashboard.stiTestOrderStatusCard.description.dispatched'],
      button: {
        text: translations['prepDashboard.stiTestOrderStatusCard.ctaButtonText.dispatched'],
        link: '/sti-test-kits',
      },
      showStatusUpdate: true,
    },
    returnKitReminder: {
      title: translations['prepDashboard.stiTestOrderStatusCard.title.returnKitReminder'],
      content: translations['prepDashboard.stiTestOrderStatusCard.description.returnKitReminder'],
      button: null,
      showStatusUpdate: order.updatedAt < subDays(new Date(), 7),
    },
    labReceipt: {
      title: translations['prepDashboard.stiTestOrderStatusCard.title.labReceipt'],
      content: translations['prepDashboard.stiTestOrderStatusCard.description.labReceipt'],
      button: null,
      showStatusUpdate: true,
    },
    resultsReady: {
      title: translations['prepDashboard.stiTestOrderStatusCard.title.resultsReady'],
      content: translations['prepDashboard.stiTestOrderStatusCard.description.resultsReady'],
      button: {
        text: translations['prepDashboard.stiTestOrderStatusCard.ctaButtonText.resultsReady'],
        link: `/account/results/${order.sh24Uid}`,
      },
      showStatusUpdate: order.updatedAt > subDays(new Date(), 7),
    },
    resultsUpdated: {
      title: translations['prepDashboard.stiTestOrderStatusCard.title.resultsUpdated'],
      content: translations['prepDashboard.stiTestOrderStatusCard.description.resultsUpdated'],
      button: {
        text: translations['prepDashboard.stiTestOrderStatusCard.ctaButtonText.resultsUpdated'],
        link: `/account/results/${order.sh24Uid}`,
      },
      showStatusUpdate: order.updatedAt > subDays(new Date(), 7),
    },
    resultsHeld: {
      title: translations['prepDashboard.stiTestOrderStatusCard.title.resultsHeld'],
      content: translations['prepDashboard.stiTestOrderStatusCard.description.resultsHeld'],
      button: deriveResultsHeldButton(order, translations),
      showStatusUpdate: true,
    },
    additionalKitApproved: {
      title: translations['prepDashboard.stiTestOrderStatusCard.title.additionalKitApproved'],
      content: translations['prepDashboard.stiTestOrderStatusCard.description.additionalKitApproved'],
      button: null,
      showStatusUpdate: true,
    },
    additionalKitDispatched: {
      title: translations['prepDashboard.stiTestOrderStatusCard.title.additionalKitDispatched'],
      content: translations['prepDashboard.stiTestOrderStatusCard.description.additionalKitDispatched'],
      button: {
        text: translations['prepDashboard.stiTestOrderStatusCard.ctaButtonText.dispatched'],
        link: '/sti-test-kits',
      },
      showStatusUpdate: true,
    },
  }
)
const StiTestOrderStatus = ({
  stiTestOrderData = null,
}: {
  stiTestOrderData?: PrepSubscriptionStiTestOrderData | null
}) => {
  const translations = useTranslations()

  const renderStatusCard = (orderData: PrepSubscriptionStiTestOrderData) => {
    const showReturnKitReminder = orderData.orderState === 'dispatched' && (orderData.updatedAt < subDays(new Date(), 7))
    const stateCamelized = camelize(orderData.orderState)
    const statusDetails = fetchStatusDetails(orderData, translations)
    const statusCardDetails = showReturnKitReminder ? statusDetails.returnKitReminder : statusDetails[stateCamelized]

    if (statusCardDetails === undefined || (statusCardDetails && !statusCardDetails.showStatusUpdate)) {
      return null
    }

    const ctaButton = statusCardDetails.button && (
      <CtaButtonOuterWrapper>
        <ButtonLink variation="primary" fullWidth>
          <InlineLink url={statusCardDetails.button.link} text={statusCardDetails.button.text} />
        </ButtonLink>
      </CtaButtonOuterWrapper>
    )

    return (
      <Callout
        key="prepOrder"
        preTitle={format(orderData.updatedAt, 'dd MMMM HH:mm')}
        backgroundColour="/special200"
        iconBackgroundColour="/special300"
        icon="bell"
        title={statusCardDetails.title}
        content={<p>{statusCardDetails.content}</p>}
        ctaButton={ctaButton}
      />
    )
  }

  return (
    <>
      {stiTestOrderData && renderStatusCard(stiTestOrderData)}
    </>
  )
}

export default StiTestOrderStatus
