import { ButtonLink, Callout } from '@sh24/ui-components'
import styled from 'styled-components'
import useTranslations from '../../../../utils/use-translations'
import InlineLink from '../../../InlineLink/inline-link'

const CtaButtonOuterWrapper = styled.div`
  div {
    a {
      justify-content: center;
    }
  }
`

const RepeatHivTestStatus = () => {
  const translations = useTranslations()

  const title = translations['prepDashboard.repeatHivTestStatusCard.title']
  const description = translations['prepDashboard.repeatHivTestStatusCard.description']
  const buttonText = translations['prepDashboard.repeatHivTestStatusCard.buttonText']

  const ctaButton = (
    <CtaButtonOuterWrapper>
      <ButtonLink variation="primary" fullWidth>
        <InlineLink url="/orders/prep-extra-test/new" text={buttonText} />
      </ButtonLink>
    </CtaButtonOuterWrapper>
  )

  return (
    <Callout
      backgroundColour="/special200"
      iconBackgroundColour="/special300"
      icon="bell"
      title={title}
      content={<p>{description}</p>}
      ctaButton={ctaButton}
    />
  )
}

export default RepeatHivTestStatus
