import styled from 'styled-components'
import { Tag, BreakpointHelpers } from '@sh24/ui-components'
import documentToReactComponents from '../../../utils/document-to-react-components'
import Image from '../../Image/image'
import CalloutModule from '../CalloutModule/callout-module'

const { mobileOnly, tabletUp } = BreakpointHelpers

const ContentAndTabletUpImageContainer = styled.div`
  ${({ theme }) => `
    ${tabletUp(`
      display: flex;
      gap: ${theme?.spacing?.md};
      align-items: center;
      margin-bottom: ${theme?.spacing?.lg};
    `)}
  `}
`

const TabletUpImageContainer = styled.div`
  display:  none;
  ${tabletUp(`
    display: block;

    img {
      min-width: 300px;
      width: 100%;
      height: auto;
    }
  `)}
`

const TagsAndMobileOnlyImageContainer = styled.div`
  ${({ theme }) => `
    ${mobileOnly(`
      display: flex;
      gap: ${theme?.spacing?.sm};
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${theme?.spacing?.md};
    `)}
  `}
`

const MobileOnlyImageContainer = styled.div`
  display: block;

  img {
    min-width: 100px;
    width: 100%;
    height: auto;
  }

  ${tabletUp(`
    display: none;
  `)}
`

const CalloutsContainer = styled.div`
  ${tabletUp(`
    max-width: 75%;
  `)}
`

const { desktopUp } = BreakpointHelpers

const TagsList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;

  ${desktopUp(`
    display: flex;
  `)}
`

const TagsListItem = styled.li`
  ${({ theme }) => `
    display: inline-block;
    margin-right: ${theme?.spacing?.sm};

    > span {
      display: flex;
      align-items: center;
    }

    ${desktopUp(`
      margin-bottom: 0;
      div {
        text-wrap: nowrap;
      }
    `)}
  `}
`

type Callout = {
  id: number,
  backgroundColour: string,
  iconBackgroundColour: string,
  icon: string,
  title: string,
  content: object,
  link: object,
  embed?: boolean | undefined,
}

const IntroductionModule = (
  {
    heading,
    subheading,
    body,
    image,
    tags,
    callouts,
  } : {
  heading: string,
  subheading: string,
  body: object,
  image: { src: string, srcWidth: number, srcHeight: number },
  tags: { id: string, title: string, icon?: object }[],
  callouts: Callout[],
}) => {
  const bodyDoc = documentToReactComponents(body)

  return (
    <div className="row row-centered">
      <div className="col col-12 col-md-8">
        <div>

          <ContentAndTabletUpImageContainer>
            <div>
              <h2>{heading}</h2>
              <h4 className="mb-sm">{subheading}</h4>
              {bodyDoc}

              <TagsAndMobileOnlyImageContainer>
                {tags && (
                  <TagsList>
                    {tags.map((tag) => (
                      <TagsListItem key={tag.id}>
                        <Tag text={tag.title} icon={tag.icon} />
                      </TagsListItem>
                    ))}
                  </TagsList>
                )}

                <MobileOnlyImageContainer>
                  <Image
                    image={image}
                    layout="intrinsic"
                    maxWidth="300"
                    maxHeight="300"
                    future
                  />
                </MobileOnlyImageContainer>
              </TagsAndMobileOnlyImageContainer>
            </div>

            <TabletUpImageContainer>
              <Image
                image={image}
                layout="intrinsic"
                maxWidth="300"
                maxHeight="300"
                future
              />
            </TabletUpImageContainer>
          </ContentAndTabletUpImageContainer>

          <CalloutsContainer>
            {callouts?.map((callout) => <CalloutModule {...callout} embed />)}
          </CalloutsContainer>

        </div>
      </div>
    </div>
  )
}

export default IntroductionModule
