import { useContext } from 'react'
import styled from 'styled-components'

import { PrepSubscriptionData } from '@sh24/admin-api-js'
import { BreakpointHelpers } from '@sh24/ui-components'
import AccountContext from '../../../../contexts/account-context'
import ensurePrepSubscriptionLoaded from '../../../../utils/ensure-prep-subscription-loaded'
import StatusContainer from '../../../PrepDashboard/PrepDashboardStatus/status-container'
import NewToPrepCallout from '../../../PrepDashboard/PrepDashboardStatus/PrepOrderStatus/new-to-prep-callout'
import OrderAvailabilityContainer from '../../../PrepDashboard/OrderAvailability/order-availability-container'
import useTranslations from '../../../../utils/use-translations'
import { ErrorData } from '../../../../types/api'
import RestartPrepContainer from '../../../PrepDashboard/RestartPrep/restart-prep-container'

const { mobileOnly, tabletUp } = BreakpointHelpers

const Container = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
    display: flex;
    height: auto;

    ${mobileOnly(`
      flex-direction: column;
      gap: ${theme?.spacing?.sm};
    `)}

    ${tabletUp(`
      flex-direction: row;
      justify-content: center;
      gap: ${theme?.spacing?.md};

      > div {
        width: 40%;
      }
    `)}
  `}
`

const Centered = styled.div`
  text-align: center;
`
const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StatusWrapper = styled.div`
    justify-content: center;
  width: 100%;
  margin: auto;

  ${tabletUp(`
  width: 80%;
      `)}

  ${mobileOnly(`
    `)}
`

const OrderAvailabilityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  margin: auto;

  ${tabletUp(`
        width: 80%;
      `)}

  ${mobileOnly(`
    `)}
`

const PrepActionsModule = () => {
  const { prepSubscriptionContext } = useContext(AccountContext)
  const {
    prepSubscription,
    errors,
  }: {
    prepSubscription: PrepSubscriptionData | null,
    errors: ErrorData | null,
  } = prepSubscriptionContext

  ensurePrepSubscriptionLoaded()

  const translations = useTranslations()

  if ((errors?.errors)) {
    return (
      <>
        <h2>An error occurred</h2>
        <p>
          There was a problem displaying your prep subscription. This has been reported
          and will be looked in to shortly. Please come back and try again
          later.
        </p>
      </>
    )
  }

  if (!prepSubscription) {
    return (
      <Centered>
        <p>{translations['prepDashboard.loadingPrepSubscription']}</p>
      </Centered>
    )
  }

  if (!prepSubscription.prepSubscription) {
    return (
      <Container>
        <NewToPrepCallout />
      </Container>
    )
  }

  if (prepSubscription.prepSubscription.state === 'closed') {
    return (
      <RestartPrepContainer />
    )
  }

  return (
    <div>
      <InnerContainer>
        <StatusWrapper>
          <StatusContainer
            lastOrders={prepSubscription.lastOrders}
            repeatHivTest={prepSubscription.availableServices?.repeatHivTest}
          />
        </StatusWrapper>
        <OrderAvailabilityWrapper>
          <OrderAvailabilityContainer
            availableServices={prepSubscription.availableServices}
            prepConsultation={prepSubscription.lastOrders?.prepOrder?.consultation}
          />
        </OrderAvailabilityWrapper>
      </InnerContainer>
    </div>
  )
}
export default PrepActionsModule
