import { ReactNode } from 'react'
import type { Result, TestOutcome, StiTestType } from '@sh24/admin-api-js'
import { ResultsHistoryCard as ResultsHistoryCardComponent } from '@sh24/ui-components'
import groupBy from '../../utils/group-by'
import { resultOutcomeText, derivedResultPillBackgroundColour } from '../ResultCards/result-helpers'
import useThemeContext from '../../utils/use-theme-context'

const INFECTIONS = {
  chlamydia: 'Chlamydia',
  gonorrhoea: 'Gonorrhoea',
  hepatitisB: 'Hepatitis B',
  hepatitisC: 'Hepatitis C',
  hiv: 'HIV',
  syphilis: 'Syphilis',
  kidneyFunction: 'Kidney Function',
}

const SITES = {
  anal: 'anal',
  oral: 'oral',
  urine: 'genital',
  vaginal: 'genital',
}

const SAMPLE_TYPE = {
  swab: 'swab',
  urine: 'urine',
  blood: 'blood',
}

const testTypesMap: Record<StiTestType, {
  infection: string,
  site?: string,
  sampleType: string,
  testPerformed?: 'eia' | 'rpr',
}> = {
  'Chlamydia::Anal': { infection: INFECTIONS.chlamydia, site: SITES.anal, sampleType: SAMPLE_TYPE.swab },
  'Chlamydia::Oral': { infection: INFECTIONS.chlamydia, site: SITES.oral, sampleType: SAMPLE_TYPE.swab },
  'Chlamydia::Urine': { infection: INFECTIONS.chlamydia, site: SITES.urine, sampleType: SAMPLE_TYPE.urine },
  'Chlamydia::Vaginal': { infection: INFECTIONS.chlamydia, site: SITES.vaginal, sampleType: SAMPLE_TYPE.swab },
  'Gonorrhoea::Anal': { infection: INFECTIONS.gonorrhoea, site: SITES.anal, sampleType: SAMPLE_TYPE.swab },
  'Gonorrhoea::Oral': { infection: INFECTIONS.gonorrhoea, site: SITES.oral, sampleType: SAMPLE_TYPE.swab },
  'Gonorrhoea::Urine': { infection: INFECTIONS.gonorrhoea, site: SITES.urine, sampleType: SAMPLE_TYPE.urine },
  'Gonorrhoea::Vaginal': { infection: INFECTIONS.gonorrhoea, site: SITES.vaginal, sampleType: SAMPLE_TYPE.swab },
  'HepatitisB::Blood': { infection: INFECTIONS.hepatitisB, sampleType: SAMPLE_TYPE.blood },
  'HepatitisC::Blood': { infection: INFECTIONS.hepatitisC, sampleType: SAMPLE_TYPE.blood },
  'Hiv::Blood': { infection: INFECTIONS.hiv, sampleType: SAMPLE_TYPE.blood },
  'KidneyFunction::SerumCreatinine': { infection: INFECTIONS.kidneyFunction, sampleType: SAMPLE_TYPE.blood },
  'Syphilis::RPR': { infection: INFECTIONS.syphilis, sampleType: SAMPLE_TYPE.blood, testPerformed: 'rpr' },
  'Syphilis::Treponemal': { infection: INFECTIONS.syphilis, sampleType: SAMPLE_TYPE.blood, testPerformed: 'eia' },
}

const getTestTypeDetails = (testType: StiTestType) => testTypesMap[testType]

const outcomeColors = {
  negative: '#C9FAC8',
  positive: '#FFC7CC',
  reactive: '#FFF1BF',
  action: '#EBF2F2',
}

const getOutcomeColor = (outcome: string) => {
  if (outcome === 'negative' || outcome === 'positive' || outcome === 'reactive') {
    return outcomeColors[outcome]
  }
  return outcomeColors.action
}

type GroupedResult = {
  infection: string,
  outcome: TestOutcome
  sites: string[],
}

const getOutcomeOrder = (outcome: TestOutcome | number) => {
  if (typeof outcome === 'number') {
    return 98
  }
  return {
    positive: 1,
    reactive: 2,
    missing: 3,
    equivocal: 4,
    haemolysed: 4,
    insufficient: 4,
    invalid_sample: 4,
    lab_error: 4,
    not_processed: 4,
    negative: 5,
    numerical: 98,
    unknown: 99,
  }[outcome]
}

const resultSort = (a: GroupedResult, b: GroupedResult) => {
  // Sort outcomes according to custom order
  if (getOutcomeOrder(a.outcome) < getOutcomeOrder(b.outcome)) return -1
  if (getOutcomeOrder(a.outcome) > getOutcomeOrder(b.outcome)) return 1

  // Sort infections alphabetically
  if (a.infection.localeCompare(b.infection) < 0) return -1
  if (a.infection.localeCompare(b.infection) > 0) return 1

  return 0
}

const latestResult = (results: Array<Result>) => results.sort(
  (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
)[0]

const ResultsHistoryCard = ({
  results,
  orderId,
  status,
  cta,
}: {
  results: Result[]
  orderId: string
  status: string
  cta: ReactNode
}) => {
  const theme = useThemeContext()

  const resultsByTestType = groupBy(results, (result) => result.test)
  const latestResultsByTestType = new Map(Array.from(resultsByTestType).map(
    (entry) => ([entry[0], latestResult(entry[1])]),
  ))
  const latestResults = Array.from(latestResultsByTestType.values())

  // Group results by Infection and Outcome
  const groupedResults = latestResults.reduce((acc, result) => {
    const { infection, site } = getTestTypeDetails(result.test)
    const { outcome } = result

    if (infection === INFECTIONS.kidneyFunction) {
      return acc
    }

    let sameInfectionAndOutcome = acc.find(
      (group) => group.infection === infection && group.outcome === outcome,
    )

    if (!sameInfectionAndOutcome) {
      sameInfectionAndOutcome = { infection, outcome, sites: [] }
      acc.push(sameInfectionAndOutcome)
    }

    if (site) {
      sameInfectionAndOutcome.sites.push(site)
    }

    return acc
  }, [] as GroupedResult[])

  const formattedResults = groupedResults.sort(resultSort).map((result) => ({
    pillBackgroundColour: getOutcomeColor(result.outcome),
    pillText: result.outcome as string,
    site: result.sites.join(', '),
    infection: result.infection,
  }))

  const kidneyFunctionResult = latestResults.find(
    (result) => getTestTypeDetails(result.test).infection === INFECTIONS.kidneyFunction,
  )

  if (
    kidneyFunctionResult
    && kidneyFunctionResult.numericalResult
    && kidneyFunctionResult.linkedDerivedResults
    && kidneyFunctionResult.linkedDerivedResults.egfr
  ) {
    const egfrOutcomeText = resultOutcomeText(
      { test: 'egfr', outcome: kidneyFunctionResult.linkedDerivedResults.egfr },
    )
    const egfrPillColour = derivedResultPillBackgroundColour(
      'egfr', kidneyFunctionResult.linkedDerivedResults.egfr,
    ) || theme?.palette?.tertiary200
    const scOutcomeText = resultOutcomeText(
      { test: 'kidneyFunction', outcome: kidneyFunctionResult.numericalResult.toString() },
    )

    const formattedKidneyFunctionResults = [
      {
        pillBackgroundColour: getOutcomeColor(kidneyFunctionResult.outcome),
        pillText: scOutcomeText,
        site: '',
        infection: 'Serum Creatinine',
      },
      {
        pillBackgroundColour: egfrPillColour,
        pillText: egfrOutcomeText,
        site: '',
        infection: 'eGFR',
      },
    ]
    formattedResults.push(...formattedKidneyFunctionResults)
  }

  return (
    <ResultsHistoryCardComponent
      results={formattedResults}
      orderId={orderId}
      status={status}
      cta={cta}
    />
  )
}

export default ResultsHistoryCard
