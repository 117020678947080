import { useContext, useEffect } from 'react'
import { PrepSubscriptionData } from '@sh24/admin-api-js'
import AccountContext from '../../../../contexts/account-context'
import ensurePrepSubscriptionLoaded from '../../../../utils/ensure-prep-subscription-loaded'
import PageContext from '../../../../contexts/page-context'
import PrepCallout from '../../../AccountHomepage/PrepCallout/prep-callout'

interface PageContext {
  updateRenderModules: (id: string, shouldRender: boolean) => void
}

const PrepCalloutModule = ({ id }: {id: string}) => {
  const { prepSubscriptionContext } = useContext(AccountContext)
  const {
    prepSubscription,
  }: {
    prepSubscription: PrepSubscriptionData | null,
  } = prepSubscriptionContext
  const { updateRenderModules } = useContext(PageContext) as PageContext

  ensurePrepSubscriptionLoaded()

  const activePrepSubscription = prepSubscription?.prepSubscription
    && prepSubscription.prepSubscription.state === 'active'

  useEffect((() => {
    if (activePrepSubscription) {
      updateRenderModules(id, true)
    } else {
      updateRenderModules(id, false)
    }
  }), [prepSubscription?.prepSubscription])

  if (activePrepSubscription) {
    return (
      <div className="row row-centered">
        <div className="col col-12 col-md-6 col-lg-4">
          <PrepCallout />
        </div>
      </div>
    )
  }

  return null
}

export default PrepCalloutModule
