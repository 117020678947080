import { ButtonLink, Callout } from '@sh24/ui-components'
import styled from 'styled-components'
import InlineLink from '../../InlineLink/inline-link'
import useTranslations from '../../../utils/use-translations'

const CtaButtonOuterWrapper = styled.div`
  div {
    a {
      justify-content: center;
    }
  }
`

const PrepCallout = () => {
  const translations = useTranslations()

  const goToPrepDashboardBtn = (
    <CtaButtonOuterWrapper>
      <ButtonLink variation="primary" fullWidth>
        <InlineLink
          url="/account/prep"
          text={translations['accountHomepage.prepCallout.buttonText']}
        />
      </ButtonLink>
    </CtaButtonOuterWrapper>
  )

  return (
    <Callout
      backgroundColour="/primary"
      title={<h3>{translations['accountHomepage.prepCallout.title']}</h3>}
      content={<p>{translations['accountHomepage.prepCallout.description']}</p>}
      ctaButton={goToPrepDashboardBtn}
    />
  )
}

export default PrepCallout
